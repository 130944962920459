import React from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import SpinnerIcon from './spinner'
import SuccessIcon from './success'
import Turnstile, { useTurnstile } from 'react-turnstile'
const apiUrl = process.env.REACT_APP_API_URL
const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

function FormComponent() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setError,
        reset,
    } = useForm()

    // State to hold server-side error
    const [recaptchaToken, setRecaptchaToken] = React.useState('')
    const [serverError, setServerError] = React.useState('')
    const [success, setSuccess] = React.useState(false)

    const [submitting, setSubmitting] = React.useState(false)
    const turnstile = useTurnstile()
    const onSubmit = async (data) => {
        setSuccess(false)
        if (!recaptchaToken) {
            setError('recaptcha_response', {
                type: 'manual',
                message: 'Please complete the recaptcha.',
            })
            return
        } else {
            data.recaptcha_response = recaptchaToken
        }

        clearErrors('server') // Clear previous server errors before a new submission
        setServerError('')
        setSubmitting(true)
        try {
            const response = await axios.post(`${apiUrl}/sdp/`, data)
            console.log(response.data.message)
            setSuccess(true)
            reset()
            setTimeout(() => {
                setSuccess(false)
            }, 2500)
        } catch (error) {
            if (error.response && error.response.data) {
                setServerError(error.response.data.message)
                setError('root.serverError', {
                    type: '400',
                    message: error.response.data.message,
                })
            } else {
                setServerError('An unexpected error occurred.')
            }
        } finally {
            setSubmitting(false)
            turnstile.reset()
        }
    }

    const getButtonContext = () => {
        if (success) {
            return <SuccessIcon />
        }
        if (submitting) {
            return <SpinnerIcon isSpinning={true} />
        }
        return <span>Submit</span>
    }

    return (
        <form
            className="form-wrapper"
            onSubmit={handleSubmit(onSubmit)}
            style={{ maxWidth: '436px' }}
        >
            <input
                id="game_username"
                type="text"
                className={
                    'input-field' + (errors.game_username ? ' form-error' : '')
                }
                placeholder="Username*"
                {...register('game_username', {
                    required: 'Game username is required',
                })}
            />
            {errors.game_username && (
                <p className="error-message">{errors.game_username.message}</p>
            )}

            <input
                id="email"
                type="email"
                className={'input-field' + (errors.email ? ' form-error' : '')}
                placeholder="Email*"
                {...register('email', {
                    required: 'Email address is required',
                })}
            />
            {errors.email && (
                <p className="error-message">{errors.email.message}</p>
            )}

            <input
                id="discord_username"
                type="text"
                className="input-field"
                placeholder="Discord"
                {...register('discord_username')}
            />

            <input
                id="telegram_username"
                type="text"
                className="input-field"
                placeholder="Telegram"
                {...register('telegram_username')}
            />

            <input
                id="validator_enode"
                type="text"
                className={
                    'input-field' +
                    (errors.validator_enode ? ' form-error' : '')
                }
                placeholder="Validator Enode*"
                {...register('validator_enode', {
                    required: 'Validator enode is required',
                    pattern: {
                        value: /^enode:\/\/.*/,
                        message: 'Validator enode must start with enode://',
                    },
                })}
            />
            {errors.validator_enode && (
                <p className="error-message">
                    {errors.validator_enode.message}
                </p>
            )}

            <div>
                <p className="form-text form-notification">
                    Sign the below message using your validator key:
                </p>
                <p className="form-text message">
                    Application for the stake delegation program
                </p>
            </div>

            <input
                id="signature"
                type="text"
                className={
                    'input-field signature' +
                    (errors.signature ? ' form-error' : '')
                }
                placeholder="Signature*"
                {...register('signature', {
                    required: 'Signature is required',
                    pattern: {
                        value: /^0x[a-fA-F0-9]{130}$/,
                        message:
                            'Signature must be a 130 character hexadecimal starting with 0x',
                    },
                })}
            />
            {errors.signature && (
                <p className="error-message">{errors.signature.message}</p>
            )}

            <Turnstile sitekey={siteKey} onVerify={setRecaptchaToken} />
            <button className="submit-button" type="submit">
                {getButtonContext()}
            </button>

            <p className={'response' + (serverError ? ' server-error' : '')}>
                {serverError && serverError}
            </p>
        </form>
    )
}

export default FormComponent
