const SuccessIcon = () => {
    return (
        <div class="animation-container">
            <div class="icon icon--order-success svg">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                >
                    <g fill="none" stroke="#22AE73" stroke-width="1">
                        <circle
                            cx="15"
                            cy="15"
                            r="14"
                            style={{
                                'stroke-dasharray': '96px, 96px',
                                'stroke-dashoffset': '192px',
                            }}
                        ></circle>
                        <circle
                            id="colored"
                            fill="#22AE73"
                            cx="15"
                            cy="15"
                            r="14"
                            style={{
                                'stroke-dasharray': '96px, 96px',
                                'stroke-dashoffset': '192px',
                            }}
                        ></circle>
                        <polyline
                            class="st0"
                            stroke="#fff"
                            stroke-width="2"
                            points="8.7,15.55 12.74,19.58 22.44,9.87 "
                            style={{
                                'stroke-dasharray': '20px, 20px',
                                'stroke-dashoffset': '40px',
                            }}
                        />
                    </g>
                </svg>
            </div>
        </div>
    )
}

export default SuccessIcon
