import './app.css'
import 'katex/dist/katex.min.css'
import { useEffect, useState, useRef } from 'react'
import { ReactTyped } from 'react-typed'
import Latex from 'react-latex-next'
import FormComponent from './component/form'
const App = () => {
    const [active, setActive] = useState('howto')
    const sectionsRef = useRef([])
    const isActive = (tab) => (tab === active ? 'active' : '')

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActive(entry.target.id)
                }
            })
        }, options)
        const currentSections = sectionsRef.current
        currentSections.forEach((section) => {
            if (section) {
                observer.observe(section)
            }
        })

        return () => {
            currentSections.forEach((section) => {
                if (section) {
                    observer.unobserve(section)
                }
            })
        }
    }, [])

    return (
        <div className="App">
            <div className="content-container">
                <div className="full splash">
                    <div className="splash-content">
                        <h1 id="splash">Autonity Stake Delegation Program</h1>
                        <ReactTyped
                            startWhenVisible
                            className="subtitle"
                            strings={[
                                'Join the Future of Decentralized Finance with Autonity',
                            ]}
                            typeSpeed={18}
                        />
                        <div className="button-container">
                            <a className="big button" href="#join">
                                Join now
                            </a>
                            <a className="big button" href="#howto">
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>
                <div>
                    <div id="join" className="sticky">
                        <div className="form-container">
                            <FormComponent />
                        </div>
                    </div>
                    <div className="breadcrumb-container">
                        <div className="breadcrumbs">
                            <div className={isActive('howto')}>
                                <a
                                    href="#howto"
                                    onClick={() => setActive('howto')}
                                >
                                    How to join
                                </a>
                            </div>
                            <div className={isActive('qualification')}>
                                <a
                                    href="#qualification"
                                    onClick={() => setActive('qualification')}
                                >
                                    Qualification
                                </a>
                            </div>
                            <div className={isActive('allocation')}>
                                <a
                                    href="#allocation"
                                    onClick={() => setActive('allocation')}
                                >
                                    Allocation
                                </a>
                            </div>
                            <div className={isActive('formula')}>
                                <a
                                    href="#formula"
                                    onClick={() => setActive('formula')}
                                >
                                    Formula
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        id="howto"
                        className="full content"
                        ref={(el) => (sectionsRef.current[0] = el)}
                    >
                        <div className="wrapper">
                            <h2>How To Join</h2>
                            <p>
                                To register your interest, please submit a form
                                with your validator address and contact details.
                                <br />
                                <br />
                                To be considered for the Stake Delegation
                                Program, you must ensure that you meet the
                                qualification criteria outlined below.
                                <br /> Additionally, you must satisfy the
                                allocation criteria to receive a delegation from
                                the Stake Delegation Program.
                            </p>
                        </div>
                        <a href="#qualification" className="hash-anchor">
                            What are the qualification criteria?
                        </a>
                        <br />
                        <br />
                        <a href="#allocation" className="hash-anchor">
                            What is the allocation criteria?
                        </a>
                        <br />
                        <br />
                        <a href="#formula" className="hash-anchor">
                            How are allocations calculated?
                        </a>
                    </div>
                    <div
                        id="qualification"
                        className="full content"
                        ref={(el) => (sectionsRef.current[1] = el)}
                    >
                        <div className="wrapper">
                            <h2>Qualification Criteria</h2>
                            <p>
                                In order to qualify for the Stake Delegation
                                Program, you must have:
                            </p>
                            <ul>
                                <li>
                                    No more than 700,000 NTN in total stake on
                                    validator.
                                </li>
                                <li>
                                    At least 7,000 NTN in self-bonded stake.
                                </li>
                                <li>Commission rate 10% or lower.</li>
                                <li>
                                    Penalty Absorbing Stake ratio (ratio of
                                    self-bonded stake to total stake) at least
                                    25%.
                                </li>
                            </ul>
                            <br />
                            <p>
                                Applications are continuously monitored and a
                                rebalancing of delegations will take place at
                                17:00 UTC every 3 days (starting on 1 July) to
                                redistribute stake amongst qualifying
                                participants.
                                <br />
                                <br />
                                The criteria above must be satisfied
                                continuously, even in-between rebalancing
                                periods otherwise a validator will be
                                disqualified for 3 rebalancing periods.
                                <br />
                                <br />
                                Satisfying these criteria does not guarantee you
                                will receive a delegation. You will only receive
                                a delegation from the SDP if the below
                                allocation criteria can also be satisfied.
                            </p>
                        </div>
                        <a href="#allocation" className="hash-anchor">
                            What is the allocation criteria?
                        </a>
                    </div>
                    <div
                        id="allocation"
                        className="full content"
                        ref={(el) => (sectionsRef.current[2] = el)}
                    >
                        <div className="wrapper">
                            <h2>Allocation Criteria</h2>
                            <p>
                                In order to be allocated appropriately, you
                                must:
                            </p>
                            <ul>
                                <li>
                                    Have received no more than 100,000 NTN from
                                    the SDP
                                </li>
                                <li>
                                    Have a maximum of 600,000 NTN in total stake
                                    on validator.
                                </li>
                                <li>
                                    Have a Penalty Absorbing Stake ratio (ratio
                                    of self-bonded stake to total stake) of at
                                    least 35%.
                                </li>
                                <li>
                                    Be in the top 2 * maximum committee size
                                    weighted by stake.
                                </li>
                            </ul>
                            <p>
                                The delegation that you will receive is
                                calculated to meet all of the above criteria.
                            </p>
                        </div>
                        <a href="#formula" className="hash-anchor">
                            How are allocations calculated?
                        </a>
                    </div>
                    <div
                        id="formula"
                        className="full content"
                        ref={(el) => (sectionsRef.current[3] = el)}
                    >
                        <div className="wrapper">
                            <h2>Allocation Formula</h2>
                            <p>
                                Allocations are calculated with the following
                                formula:
                            </p>
                            <ol>
                                <li>
                                    Calculate a proposed delegation
                                    <Latex> $D_i^P$ </Latex>
                                    for validator<Latex> $i$ </Latex>that
                                    satisfies the
                                    <Latex> $600,000$ </Latex>NTN maximum total
                                    stake criterion but doesn't exceed the
                                    <Latex> $100,000$ </Latex>
                                    NTN maximum delegation:
                                    <Latex>
                                        $$D_i^P = 600,000 - TBS_i; 0 \le D_i^P
                                        \le 100,000$$
                                    </Latex>
                                    where<Latex> $TBS_i$ </Latex>is the total
                                    bonded stake on validator
                                    <Latex> $i$ </Latex>without any existing SDP
                                    delegation.
                                </li>
                                <li>
                                    Calculate a deduction{' '}
                                    <Latex> $D_i^D$ </Latex> to satisfy the
                                    <Latex> $35\%$ </Latex> minimum PAS ratio
                                    criterion if possible:
                                    <Latex>
                                        {
                                            '$$\\frac{SBS_i}{TBS_i + D_i^P - D_i^D} = 0.35; 0 \\le D_i^D \\le D_i^P$$'
                                        }
                                    </Latex>
                                    where<Latex> $SBS_i$ </Latex>is the
                                    self-bonded stake on validator
                                    <Latex> $i$</Latex>.
                                    <br />
                                    Rearranged for<Latex> $D_i^D$</Latex>:
                                    <Latex>
                                        {
                                            '$$D_i^D = TBS_i + D_i^P - \\frac{SBS_i}{0.35}; 0 \\le D_i^D \\le D_i^P$$'
                                        }
                                    </Latex>
                                </li>
                                <li>
                                    The delegation for validator
                                    <Latex> $i$ </Latex>is:
                                    <Latex>$$D_i = D_i^P - D_i^D$$</Latex>
                                </li>
                                <li>
                                    Calculate a shrinkage ratio
                                    <Latex> $R$ </Latex>so that the total
                                    delegation does not exceed the
                                    <Latex> $7,600,000$ </Latex>NTN budget:
                                    <Latex>
                                        {
                                            '$$R = \\frac{7,600,000}{\\sum\\limits_i D_i}; R \\le 1$$'
                                        }
                                    </Latex>
                                </li>
                                <li>
                                    Shrink each delegation as:
                                    <Latex>$$D_i^S = D_i \times R$$</Latex>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
